.ect_gh .no_result_ect_gh {
    padding-top: 80px;
}
.ect_gh.lay_conts.terms_type01 {
    padding-bottom: 80px;
}
.ect_gh.lay_conts.terms_type01 > ul:last-of-type {
    padding-bottom: 20px;
}
.ect_gh .tit02:first-child {
    margin-top: 0;
}
.ect_gh .list_share_ect_gh {
    display: flex;
    margin: 0 -20px;
    padding: 10px 0 32px 0;
    width: calc(100% + 36px);
    justify-content: space-evenly;
}
.ect_gh .list_share_ect_gh li button {
    display: block;
    text-align: center;
    font-size: 1rem;
    font-weight: 500;
    color: #171A19;
    line-height: 1.5;
    letter-spacing: -0.4px !important;
    font-family: "Happiness-Sans";
}
.ect_gh .list_share_ect_gh li button img {
    display: block;
    width: 64px;
    height: 64px;
    margin-bottom: 16px;
}
.ect_gh .btn_agreement_top {
    text-align: right;
    position: fixed;
    right: 24px;
    bottom: 24px;
}
.ect_gh .btn_agreement_top img {
    width: 40px;
    height: 40px;
}
.ect_gh.ect_gh_nopadding {
    padding: 0;
}
.ect_gh .link_opacity0_fake1 {
    position: absolute;
    left: 6.5%;
    top: 46%;
    width: 25%;
    height: 8%;
    background: none !important;
}
.ect_gh .link_opacity0_fake1 span {
    position: absolute;
    width: 1px;
    height: 1px;
    margin: -1px;
    overflow: hidden;
    clip-path: polygon(0 0, 0 0, 0 0);
}

.ect .appdownload_wrap {
    display: flex;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: center;
}
.ect .appdownload_wrap .appdownload_box {
    display: flex;
    width: 100%;
    max-width: 360px;
    height: auto;
    margin: 0 auto;
    padding-bottom: 28.61%;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.ect .appdownload_wrap .appdownload_box > .img {
    width: 100%;
}
.ect .appdownload_wrap .appdownload_box > .btn_wrap {
    width: auto;
    margin: 0 auto;
}

.lay_wrap.ect_gh_nopadding {
    padding-top: 0;
    overflow: hidden;
}
.lay_wrap.ect_gh_nopadding > .ect_gh {
    margin: 0px !important;
}
.lay_wrap.ect_gh_nopadding > .ect_gh div.slide_wrap.ban_type .slide_controls {
    visibility: hidden;
}

article.lay_pop .lay_wrap .lay_conts.terms_type01.ect_gh {
    height: calc(100% - 66px);
    padding-left: 24px;
    padding-right: 24px;
    padding-bottom: 80px;
}

article.lay_pop.bottom_type .lay_wrap.ect_gh_nopadding {
    padding-top: 0;
    overflow: hidden;
}
article.lay_pop.bottom_type .lay_wrap.ect_gh_nopadding .lay_conts.ect_gh {
    padding: 0 0 15px 0;
}
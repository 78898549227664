@charset "UTF-8";
@use "sass:math";

@import "./common/_var.scss";
@import "./common/_mixins.scss";

// @include font_sum(크기, 굵기, 색상, 간격, 자간);

.ect_gh {

	.no_result_ect_gh {
		padding-top: 80px;
	}
	&.lay_conts.terms_type01{
		> ul:last-of-type {
			padding-bottom: 20px;
		}
		padding-bottom:80px;
	}
	.tit02:first-child {
		margin-top: 0;
	}

	.list_share_ect_gh {
		display: flex;
		margin: 0 -20px;
		padding: 10px 0 32px 0;
		// width: calc(100% + 40px);
		width: calc(100% + 36px); //231015_가로스크롤 생성으로 수정(AET-E01-002B)
		justify-content: space-evenly;
		li {
			button {
				display: block;
				text-align: center;
				@include font(1rem,500,$black,1.5);
				img {
					display: block;
					width: 64px;
					height: 64px;
					margin-bottom: 16px;
				}
			}
		}
	}

	.btn_agreement_top {
		text-align: right;
		position: fixed;
		right: 24px;
		bottom: 24px;
		img {
			width: 40px;
			height: 40px;
		}
	}

	&.ect_gh_nopadding {
		padding: 0;
	}

	.link_opacity0_fake1 {
		position: absolute;
		left: 6.5%;
		top: 46%;
		width: 25%;
		height: 8%;
		background: none !important;
		span {
			@include hidden;
		}
	}



}

.ect {
	.appdownload_wrap {
		display: flex;
		position: fixed;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		align-items: center;
		justify-content: center;
		.appdownload_box {
			display: flex;
			width: 100%;
			max-width: 360px;
			height: auto;
			margin: 0 auto;
			padding-bottom: 28.61%;
			flex-direction: column;
			align-items: center;
			justify-content: center;
			> .img {
				width: 100%;
			}
			> .btn_wrap {
				width: auto;
				margin: 0 auto;
			}
		}
	}
}

//ect끝
.lay_wrap  {
	&.ect_gh_nopadding {
		padding-top: 0;
		overflow: hidden;
		> .ect_gh {
			margin: 0px !important;
			div.slide_wrap.ban_type .slide_controls {
				visibility: hidden;
			}
		}
	}
}

article.lay_pop .lay_wrap .lay_conts.terms_type01.ect_gh {
	height: calc(100% - 66px);
	//height: calc(100% - 150px);
	padding-left: 24px;
	padding-right: 24px;
	padding-bottom:80px;
}
article.lay_pop.bottom_type .lay_wrap.ect_gh_nopadding{
	padding-top: 0;
	overflow: hidden;
	.lay_conts.ect_gh {
		padding: 0 0 15px 0;
	}
}

